import About from "../components/About";
import Products from "./Products";
import Research from "./Research";
import Tech from "./Tech";

function Main() {
  return (
    <>
      <main>
        <About />
        <Research />
        <Products />
        <Tech />
      </main>
    </>
  );
}
export default Main;
