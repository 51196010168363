function About() {
  return (
    <>
      <div
        style={{ marginTop: "4rem", width: "100%", height: "10px" }}
        className="about-scroll"
      ></div>

      <div className="container about">
        <div className="row">
          <div className="col-md-6 text-center"></div>
          <div className="col-md-6">
            <h2 className="main-title about-h2 font-semibold">About</h2>
            <p className="main-p">
              Neuralmatch LLC is a tech company at the forefront of the
              intersection between artificial intelligence and human connection.
              With our ground-breaking algorithms and psychological profiling,
              we’re redefining the way people meet, interact, and form
              meaningful connections in the digital age. We believe that
              technology should serve to enhance human relationships, not
              replace them.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default About;
