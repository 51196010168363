import { Link, animateScroll as scroll } from "react-scroll";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="side1">
            <div className="row">
              <div className="col-md-3 mb-2">
                <img
                  src="img/logo-white.svg"
                  alt="logo"
                  width={150}
                  className="logo-white mt-2"
                />
                <p className="footer-text pt-2">
                  Leveling up the social experience
                </p>
              </div>
              <div className="col-md-3 mb-2">
                <p className="footer-title">Links</p>
                <ul>
                  <li>
                    <Link
                      spy={true}
                      smooth={true}
                      duration={1000}
                      to="headerbg"
                    >
                      {" "}
                      Home{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="about-scroll"
                      spy={true}
                      smooth={true}
                      duration={1000}
                    >
                      About{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="products-scroll"
                      spy={true}
                      smooth={true}
                      duration={1000}
                    >
                      Products{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="research-scroll"
                      spy={true}
                      smooth={true}
                      duration={1000}
                    >
                      Research{" "}
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="tech-scroll"
                      spy={true}
                      smooth={true}
                      duration={1000}
                    >
                      Technology{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="side2">
            <div className="row">
              <div className="col-md-3 mb-2">
                <p className="footer-title">Contact</p>
                <ul>
                  <li>
                    <Link to="#">&copy; Neural Match, LLC</Link>
                  </li>
                  <li>
                    <Link to="#">info@neuralmatch.co</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-3">
                <p className="footer-title">Social Media</p>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://github.com/neuralmatch"
                    >
                      {" "}
                      Github
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://twitter.com/neural_intl"
                    >
                      {" "}
                      Twitter
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
