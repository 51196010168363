function Header() {
  return (
    <header>
      <video
        src="./video_3.mp4"
        loop
        autoplay="autoplay"
        muted
        defaultMuted
        playsInline
      ></video>
      <h1 className="font-normal">Leveling up the social experience with AI</h1>
      <a href="#services">
        <i className="fa fa-chevron-down text-white"></i>
      </a>
      <div className="headerbg"></div>
    </header>
  );
}
export default Header;
