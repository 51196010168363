function Products() {
  return (
    <>
      <div
        style={{ width: "100%", height: "10px" }}
        className="products-scroll"
      ></div>
      <div>
        <div className="mb-2 section-img parlin-img img-right" />
      </div>

      <div className="container about">
        <div className="row">
          <div className="col-md-6 text-center"></div>
          <div className="col-md-6">
            <h2 className="main-title about-h2 font-semibold">Products</h2>
            <p className="main-p">
              Parlin is our flagship product, an innovative app that leverages
              artificial intelligence and psychological profiling to match
              individuals for engaging conversations and potential lasting
              connections. Parlin isn't just another dating app - it’s a
              community where personality leads the way, and meaningful
              connections are the norm.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default Products;
