function Tech() {
  return (
    <>
      <div
        style={{ width: "100%", height: "10px" }}
        className="tech-scroll"
      ></div>
      <div>
        <div className="mb-2 section-img tech-img img-left" />
      </div>
      <div className="container about">
        <div className="row">
          <div className="col-md-6">
            <h2 className="main-title about-h2 font-semibold">Technology</h2>
            <p className="main-p">
              Our cutting-edge technology blends artificial intelligence with
              in-depth psychological research. We employ complex algorithms and
              machine learning techniques to analyze personality traits, helping
              us find the perfect match for our users.
            </p>
          </div>
          <div className="col-md-6 text-center"></div>
        </div>
      </div>
    </>
  );
}
export default Tech;
