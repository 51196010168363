function Research() {
  return (
    <>
      <div
        style={{ width: "100%", height: "10px" }}
        className="research-scroll"
      ></div>
      <div>
        <div className="mb-2 section-img research-img img-left" />
      </div>
      <div className="container about">
        <div className="row">
          <div className="col-md-6">
            <h2 className="main-title about-h2 font-semibold">
              Psychological Research
            </h2>
            <p className="main-p">
              At Neuralmatch, we don't just prioritize understanding the human
              psyche, we also value ethical considerations in all our research
              and development. We uphold high standards of ethics and abide by a
              strict code of conduct, ensuring that the data we collect is
              always used responsibly. The information provided by our users is
              invaluable, and we pledge to always use this data with the utmost
              respect for privacy, focusing primarily on enhancing the user's
              personal experience and fostering meaningful connections.
            </p>
          </div>
          <div className="col-md-6 text-center"></div>
        </div>
      </div>
    </>
  );
}
export default Research;
